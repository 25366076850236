import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Fab from "@mui/material/Fab";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import HomeIcon from "@mui/icons-material/Home";
import MailIcon from "@mui/icons-material/Mail";
import ChatIcon from "@mui/icons-material/Chat";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DialerSipIcon from "@mui/icons-material/DialerSip";
import AddIcon from "@mui/icons-material/Add";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreIcon from "@mui/icons-material/MoreVert";
import GroupIcon from "@mui/icons-material/Group";
import AssessmentIcon from "@mui/icons-material/Assessment";
import MonitorIcon from "@mui/icons-material/Monitor";
import CallIcon from "@mui/icons-material/Call";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenRoundedIcon from "@mui/icons-material/FolderOpenRounded";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

import { PiUserSwitchLight } from "react-icons/pi";

import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

import MenuListComposition from "./AccountMenu";
import MenuListAlert from "./AlertMenu";
import AuthDialog from "app/components/Dialog/AuthDialog";
import { useEffect } from "react";
import useWindowDimensions from "app/hook/useWindowDimensions";
import { getItem, saveItem } from "store/LocalStorage";
import { refreshToken } from "utils/refreshToken";

import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userInfoSelector } from "app/slice/user/selectors";
import { useUtilsSlice } from "app/slice/utils";
import { appSelector } from "app/slice/utils/selectors";
import { useUserSlice } from "app/slice/user";
import { DIALOG_TYPE } from "app/slice/utils/types";

import PhoneApp from "app/components/PhoneApp/PhoneApp";
import { useAuth } from "../provider/ContextProvider";
import useBrowserNotification from "./Notification";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";
import { blue, deepOrange, amber, grey, green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import {
  blueberryTwilightPalette,
  mangoFusionPalette,
  cheerfulFiestaPalette,
} from "@mui/x-charts/colorPalettes";
import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);

const categories = {
  blueberryTwilight: blueberryTwilightPalette,
  mangoFusion: mangoFusionPalette,
  cheerfulFiesta: cheerfulFiestaPalette,
} as const;

type PaletteKey = keyof typeof categories;
export type PaletteMode = "light" | "dark";

interface AppProps {
  window?: () => Window;
  children: React.ReactElement;
  changeLanguage: (lng: string) => void;
}

const reloadWindow = () => {
  window.location.reload();
};

export default function AppBarWrapper(props: AppProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [openFilter, setOpenFilter] = React.useState<boolean>(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const { window, changeLanguage } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showNotification } = useBrowserNotification();

  const handleShowNotification = () => {
    showNotification(
      "Bạn có 1 thông báo mới!",
      {
        badge: "favicon.ico",
        body: "Hồ sơ của bạn đã được cập nhật trạng thái mới",
        data: { hello: "https://crm.vinacom360.com" },
        icon: "/LOGO_2.png",
        lang: "vi",
      },
      "https://crm.vinacom360.com"
    );
  };
  // useEffect(()=>{
  //   handleShowNotification()
  // },[])

  const { actions: utilsAction } = useUtilsSlice();
  const { actions: userAction } = useUserSlice();
  const userInfo = useSelector(userInfoSelector);
  const { dialogType } = useSelector(appSelector);

  const { isOpenPhone, handleOpenPhone } = useAuth();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
      mode,
      ...(mode === "light"
        ? {
            // palette values for light mode
            primary: green,
            divider: green[200],
            text: {
              primary: grey[900],
              secondary: grey[800],
            },
          }
        : {
            // palette values for dark mode
            primary: deepOrange,
            divider: deepOrange[700],
            background: {
              default: "#ffffff0",
              paper: "#1414143",
            },
            text: {
              primary: "#fff",
              secondary: "grey[500]",
            },
          }),
    },
  });
  const theme = useTheme();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [colorMode, setColorMode] = React.useState<PaletteMode>("light");
  const [themeOption, setThemeOption] = React.useState<any>(
    getItem("newTheme") || "auto"
  );

  const pathname = useLocation().pathname;
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const [heightToolBar, setHeightToolBar] = React.useState(0);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (themeOption === "auto") {
      setColorMode(prefersDarkMode ? "dark" : "light");
      if (prefersDarkMode) {
        theme.palette.mode = "dark";
      } else {
        theme.palette.mode = "light";
      }
    } else setColorMode(themeOption);
  }, [themeOption]);

  const newTheme = React.useMemo(
    () => createTheme(getDesignTokens(colorMode)),
    [colorMode]
  );

  useEffect(() => {
    if (containerRef.current) {
      setHeightToolBar(containerRef.current.offsetHeight);
    }
  }, [width]);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (userInfo?.userId) {
      navigate("/account");
    } else {
      setAnchorEl(event.currentTarget);
      onSignIn();
    }
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const onSignIn = () => {
    dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.SIGN_IN }));
  };

  const onSignOut = () => {
    dispatch(userAction.signOut({}));
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const router = React.useMemo(
    () => [
      {
        icon: <GroupIcon />,
        label: "Khách hàng",
        active: pathname === "/customers",
        href: "/customers",
      },
      // {
      //   icon: <AssessmentIcon />,
      //   label: "Báo cáo",
      //   active: pathname === "/report",
      //   href: "/report",
      // },
      {
        icon: <CallIcon />,
        label: "CDR",
        active: pathname === "/cdr",
        href: "/cdr",
      },
      {
        icon: <FolderOpenRoundedIcon />,
        label: "Quản lý File",
        active: pathname === "/filemanager",
        href: "/filemanager",
      },
      {
        icon: <MonitorIcon />,
        label: "Giám Sát",
        active: pathname === "/monitor",
        href: "/monitor",
      },
    ],
    [pathname]
  );

  const menu = () => {
    const role = localStorage.getItem("ROLE");
    const token = localStorage.getItem("TOKEN");
    return (
      <div className="w-full h-full flex flex-col gap-2 p-5 bg-gradient-to-br from-green-400 to-cyan-300">
        <Box
          // component="div"
          sx={{
            display: { xs: "none", sm: "block" },
            cursor: "pointer",
            width: "100px",
            mx: "auto",
          }}
          onClick={() => {
            navigate("/");
            setOpenDrawer(false);
          }}
        >
          <img src="/logo1x1.png" alt="logo" width={"100%"} height={"100%"} />
        </Box>
        {router.map((item) => (
          <MenuItem
            key={item.label}
            sx={{
              width: "150px",
              display: !token
                ? "none"
                : item.label !== "Giám Sát"
                ? "flex"
                : role === "admin" || role === "root"
                ? "flex"
                : "none",
              justifyContent: "start",
              alignItems: "center",
              gap: 2,
              borderRadius: 2,
              cursor: "pointer",
              bgcolor: item.active ? "orange" : "",
            }}
            onClick={() => {
              navigate(item.href);
              setOpenDrawer(false);
            }}
          >
            {item.icon}
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
        <Button
          sx={{
            display: userInfo?.userId ? "flex" : "none",
            width: "fit-content",
            ml: 1.5,
            px: 2,
            // py: 0,
            borderRadius: 5,
            bottom: 50,
            position: "absolute",
          }}
          onClick={onSignOut}
          color="inherit"
          endIcon={<LogoutIcon fontSize="small" />}
        >
          Đăng xuất
        </Button>
        <div
          className={`w-fit flex items-center border border-collapse ${
            newTheme.palette.mode === "light"
              ? "border-slate-300"
              : "border-red-400"
          } rounded-full ml-5 absolute overflow-hidden bottom-5`}
          // sx={{
          //   width: "fit-content",
          //   display: "flex",
          //   alignItems: "center",
          //   border: 0.5,
          //   borderCollapse: "collapse",
          //   borderColor: "divider",
          //   borderRadius: 5,
          //   ml: 2,
          //   bottom: 10,
          //   position: "absolute",
          //   overflow: "hidden",
          // }}
        >
          <Typography
            sx={{
              px: 1,
              py: 0.5,
              cursor: "pointer",
              bgcolor: themeOption === "light" ? "gold" : "",
            }}
            onClick={() => {
              saveItem({ newTheme: "light" });
              setThemeOption("light");
              // reloadWindow()
            }}
          >
            <LightModeIcon />
          </Typography>
          <Typography
            sx={{
              px: 1,
              py: 0.5,
              borderRight: 0.5,
              borderLeft: 0.5,
              borderColor: "divider",
              cursor: "pointer",
              bgcolor: themeOption === "auto" ? "gold" : "",
            }}
            onClick={() => {
              saveItem({ newTheme: "auto" });
              setThemeOption("auto");
              // reloadWindow()
            }}
          >
            Auto
          </Typography>
          <Typography
            sx={{
              px: 1,
              py: 0.5,
              cursor: "pointer",
              bgcolor: themeOption === "dark" ? "gold" : "",
            }}
            onClick={() => {
              saveItem({ newTheme: "dark" });
              setThemeOption("dark");
              // reloadWindow()
            }}
          >
            <DarkModeIcon />
          </Typography>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // console.log('userInfo: ', userInfo)
  }, []);

  const dialogCloseAction = () => {
    dispatch(utilsAction.setDialogApp({ dialogType: DIALOG_TYPE.CLOSE }));
  };

  const dialogRenderer = () => {
    switch (dialogType) {
      case DIALOG_TYPE.SIGN_IN:
      case DIALOG_TYPE.SIGN_UP:
        return <AuthDialog dialogCloseAction={dialogCloseAction} />;
      default:
        return <></>;
    }
  };

  const StyledFab = styled(Box)({
    width: "80px",
    height: "80px",
    position: "absolute",
    padding: "5px",
    borderRadius: "100%",
    zIndex: 1,
    top: -40,
    left: 0,
    right: 0,
    margin: "0 auto",
    boxShadow:
      "rgba(135, 135, 230, 0.25) 0px 30px 60px -12px inset, rgba(238, 137, 137, 0.3) 0px 18px 36px -18px inset",
  });

  const handleSwitch = async () => {
    const switchRefresh = getItem("switchRefresh");
    const action: any = await refreshToken(switchRefresh as string);
    // console.log('action: ', action)
    if (action) {
      localStorage.removeItem("switchRefresh");
      localStorage.removeItem("switchAccess");
      reloadWindow();
    }
  };

  interface Props {
    window?: () => Window;
    children: React.ReactElement;
  }
  function ElevationScroll(props: Props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 14,
      target: window ? window() : undefined,
    });
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
      style: {
        display: !trigger ? "flex" : "none",
        transition: "background-color 0.3s ease", // effect when trigger is true
        color: "",
      },
    });
  }

  return (
    <ThemeProvider theme={newTheme}>
      <SwipeableDrawer
        anchor={"left"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        // PaperProps={{
        //     sx: {
        //       bgcolor: 'transparent',
        //       p: { md: 3, sm: 0 },
        //       boxShadow: 'none',
        //     },
        // }}
      >
        {menu()}
      </SwipeableDrawer>
      <div
        className="grow relative w-full h-[100vh]"
        // sx={{
        //   flexGrow: 1,
        //   position: "relative",
        //   width: "100%",
        //   height: "100vh",
        // }}
      >
        {/* <CssBaseline /> */}
        <ElevationScroll>
          <AppBar
            sx={{
              background: "linear-gradient(to bottom, #66c142, #42c1bf)",
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
            ref={containerRef}
          >
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ mr: 2 }}
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Box
                // component="div"
                sx={{
                  display: { xs: "none", sm: "block" },
                  cursor: "pointer",
                  width: "100px",
                }}
                onClick={() => navigate("/")}
              >
                <img
                  src="/LOGO.png"
                  alt="logo"
                  width={"100%"}
                  height={"100%"}
                />
              </Box>
              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search> */}
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={handleSwitch}
                  sx={{
                    display: getItem("switchRefresh") ? "" : "none",
                  }}
                >
                  <PiUserSwitchLight />
                  {/* <Badge badgeContent={0} color="error">
                  </Badge> */}
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={handleOpenPhone}
                >
                  <Badge badgeContent={0} color="error">
                    <DialerSipIcon />
                  </Badge>
                </IconButton>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                  onClick={handleShowNotification}
                >
                  <Badge badgeContent={0} color="error">
                    <ChatIcon />
                  </Badge>
                </IconButton>
                {MenuListAlert()}
                {MenuListComposition()}
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <AppBar
          // ref={containerRef}
          position="fixed"
          color="primary"
          sx={{
            top: "auto",
            bottom: 0,
            background: "linear-gradient(to bottom, #66c142, #42c1bf)",
            [theme.breakpoints.up("sm")]: {
              display: "none",
            },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open home"
              onClick={() => navigate("/")}
            >
              <HomeIcon />
            </IconButton>
            <StyledFab onClick={() => navigate("/")}>
              <img src="/logo1x1.png" alt="" width={"100%"} height={"100%"} />
              {/* <AddIcon sx={{
                  width: "50px",
                  height: "50px",
                  p: '10px',
                  borderRadius: '100%',
                  background: "linear-gradient(to bottom, #34b37e, orangered)",
                }} /> */}
            </StyledFab>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="large"
              aria-label="show 4 new mails"
              color="inherit"
              onClick={handleOpenPhone}
            >
              <Badge badgeContent={0} color="error">
                <DialerSipIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit">
              <SearchIcon />
            </IconButton>
            {/* <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
              <Badge badgeContent={17} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {MenuListAlert()}
          </Toolbar>
        </AppBar>
        <Toolbar
          sx={{
            width: "100%",
            height: heightToolBar,
            [theme.breakpoints.down("sm")]: {
              display: "none",
              // position: 'absolute',
              // bottom: 0
            },
          }}
        />
        {/* {renderMobileMenu}
        {renderMenu} */}
        <main className="main">{props.children}</main>
        <Toolbar
          sx={{
            width: "100%",
            height: heightToolBar,
            [theme.breakpoints.up("sm")]: {
              display: "none",
              // position: 'absolute',
              // bottom: 0
            },
          }}
        />
        {dialogRenderer()}
      </div>
      <PhoneApp />
    </ThemeProvider>
  );
}
